import { useState, useEffect } from 'react';
import './Trybe.css'; // move all the styles here
import CustomAlert from './CustomAlert';
import { useNavigate } from 'react-router-dom';

export default function Trybe() {
  const [trybeEarnings, setTrybeEarnings] = useState(0.0);
  const [trybeCount, setTrybeCount] = useState(0);
  const [activeLevel, setActiveLevel] = useState(1);
  const [referrals, setReferrals] = useState([]);
  const [alertMessage, setAlertMessage] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const navigate = useNavigate(); // Initialize useNavigate
  
  const goBack = () => {
    navigate('/softie');
  };
  
  const closeAlert = () => {
    setAlertMessage(null); // Reset alertMessage to hide the alert
  };

  const username = localStorage.getItem('username'); // Fetch username from local storage

  useEffect(() => {
    fetchTrybeEarnings();
    fetchTotalReferrals();
    fetchReferrals(1);
  }, []);

  const fetchTrybeEarnings = async () => {
    try {
      const response = await fetch(`https://softie.onrender.com/api/users/${username}/trybeEarnings`);
      const data = await response.json();
      setTrybeEarnings(data.trybeEarnings.toFixed(2));
    } catch (error) {
      console.error('Error fetching trybe earnings:', error);
    }
  };

  const fetchTotalReferrals = async () => {
    try {
      const response = await fetch(`https://softie.onrender.com/api/users/${username}/total-referrals`);
      const data = await response.json();
      setTrybeCount(data.totalCount);
    } catch (error) {
      console.error('Error fetching total referrals:', error);
    }
  };

  const fetchReferrals = async (level) => {
    try {
      const response = await fetch(`https://softie.onrender.com/api/users/${username}/referrals/${level}`);
      const referralsData = await response.json();
      setReferrals(referralsData);
    } catch (error) {
      console.error(`Error fetching level ${level} referrals:`, error);
    }
  };

  const handleLevelClick = (level) => {
    setActiveLevel(level);
    fetchReferrals(level);
  };

  const copyReferralLink = () => {
    const referralLink = `https://app.softcoin.world/register?ref=${username}`;
    navigator.clipboard.writeText(referralLink)
      .then(() => showCustomAlert("Referral link copied to clipboard!", true))
      .catch(() => showCustomAlert("Failed to copy referral link.", false));
  };

const showCustomAlert = (message) => {
    setAlertMessage(message); // Set the custom alert message to trigger the alert
  };

  return (
    <div className="trybe-container">
      <a onClick={goBack}>
        <i className="fas fa-arrow-left" style={{ color: 'white', position: 'fixed', left: '18px', top: '40px', zIndex: '500', fontSize: '20px' }}></i>
      </a>
      <div className="blast">TRYBE</div>
      <div className="table">
      <div className="trybe-image">
        <img src="icons/trybe.png"/>
      </div>

      <div id="main">
        <div className="trybe-explain">
          <button id="toggle-button" onClick={() => document.querySelectorAll(".trybe-explain p").forEach(p => p.classList.toggle("hidden"))}>What's Trybe? <i style={{marginLeft: '10px'}} className="fas fa-arrow-down"></i>
          </button>
      <p class="hidden">TRYBE is the key to increasing your Softie earnings, and securing a source of passive income for yourself.</p>
      <p class="hidden">Your trybe includes your friends, their friends, and their friends' friends who are shareholders(Softies).</p>
      <p class="hidden">As a Trybe Leader, you earn a portion of your trybe members' daily returns: 10% from your direct referrals, 5% from their referrals, and 2% from their referrals' referrals.</p>
      <p class="hidden" style={{paddingBottom: '20px'}}>Copy your referral link to start building the ultimate Softie Trybe one member at a time, and create a powerful stream of passive income!</p>
        </div>

         {alertMessage && <CustomAlert message={alertMessage} onClose={closeAlert} />}

        <div className="trybe-earnings">
          <p>Trybe Earnings: <span id="trybe-earnings">{trybeEarnings} USD</span></p>
        </div>

        <div className="trybe-count">
          <p>Trybe Members</p>
          <p id="trybe-count">{trybeCount}</p>
        </div>

        <div className="trybe-members">
          <div className="trybe-buttons">
            {[1, 2, 3].map(level => (
              <button
                key={level}
                className={activeLevel === level ? 'active' : ''}
                onClick={() => handleLevelClick(level)}
              >
                Level {level}
              </button>
            ))}
          </div>

          <div className="heading">
            <span>Username</span><span style={{marginRight: '-25px'}}>Commitment</span><span>Earnings</span>
          </div>

          <div className="trybe-list" id="tribe-list">
            {referrals.length === 0 ? (
              <p>{activeLevel === 1 ? "You don't have a Level 1 trybe member yet. Copy your referral link and start building your trybe." : `You have no Level ${activeLevel} trybe member.`}</p>
            ) : (
              referrals.map(referral => (
                <p key={referral.username}>
                  <span id="username">{referral.username}</span>
                  <span>{referral.commitmentBalance.toFixed(2)}</span>
                  <span>{referral.earningBalance.toFixed(2)}</span>
                </p>
              ))
            )}
          </div>
        </div>

        <button className="button" id="invite-btn" onClick={copyReferralLink}>Copy Referral Link</button>
        </div>
      </div>
    </div>
  );
}
