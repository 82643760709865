import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Reset.css';
import './Register.css';

function ResendVerification() {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('');
  const navigate = useNavigate();

  const handleResendVerification = async () => {
    setLoading(true);
    try {
      const response = await fetch('https://softie.onrender.com/api/auth/resend-verification', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email }),
      });

      const result = await response.json();
      setLoading(false);
      setAlertMessage(result.message || 'Verification link sent!');
      setAlertType(response.ok ? 'success' : 'danger');

      if (response.ok) {
        setTimeout(() => {
          navigate('/login'); // Redirect to login page after 5 seconds
        }, 5000);
      }
    } catch {
      setLoading(false);
      setAlertMessage('Error sending verification link. Please try again.');
      setAlertType('danger');
    }
  };

  return (
    <div className="app">
    <div className="resend-verification-container form-body">
      <div className="login-container">
        <div className="logo-container">
        <img src="transparent-logo2/10.png" alt="Softcoin Logo" className="logo" />
      </div>
      <div className="form">
        <h2>Resend Verification Email</h2>
        <div id="alert-box" className={`custom-alert ${alertType ? `alert-${alertType}` : ''}`} style={{ display: alertMessage ? 'block' : 'none' }}>
          {alertMessage}
        </div>
        <input
          type="email"
          placeholder="Enter your email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <button className="submit-button" style={{ boxShadow: 'box-shadow: #68016600;' }} onClick={handleResendVerification} disabled={loading}>
          {loading ? 'Sending...' : 'Send Verification Link'}
        </button>
      </div>
       <div className="verify">
          <p>Enter your email address, click on the "Send Verification Link" button, and a new verification link will be sent to your mailbox.</p>
        </div>
        </div>
    </div>
    </div>
  );
}

export default ResendVerification;
