import React, { useEffect, useState } from 'react';
import BottomNav from './BottomNav';
import './Login.css';
import './Tasks.css';
import { SoundWaveLoader } from './SoundWaveLoader';
import CustomAlert from './CustomAlert';
import { taskDetails } from './taskDetails'; // Import task details
import { taskUrls } from './taskUrls';       // Import task URLs
import TaskItem from './TaskItem';           // Import TaskItem component

export default function Tasks() {
  const [activeItem, setActiveItem] = useState('tasks');
  const [loadingKey, setLoadingKey] = useState(false); // New loading state for key claim
  const [loading, setLoading] = useState(true); // Single loading state
  const [tasks, setTasks] = useState([]);
  const [username, setUsername] = useState(localStorage.getItem('username') || '');
  const [alertMessage, setAlertMessage] = useState(null);
  const [loadingTaskId, setLoadingTaskId] = useState(null);
  const [keyClaimed, setKeyClaimed] = useState(false);
  const [dailyTasks, setDailyTasks] = useState([]); // State for daily tasks
  const [showDailyTasks, setShowDailyTasks] = useState(true); // Display daily tasks by default
  const [completedDailyTasks, setCompletedDailyTasks] = useState(0); // Completed daily tasks count
  const [totalDailyTasks, setTotalDailyTasks] = useState(0); // Total daily tasks

  const closeAlert = () => {
    setAlertMessage(null);
  };

  // Constants
const CACHE_EXPIRATION_TIME = 60 * 60 * 1000; // 1 hour in milliseconds

useEffect(() => {
  if (!username) {
    window.location.href = '/login';
  } else {
    const cachedData = sessionStorage.getItem('cachedTasksData');
    
    if (cachedData) {
      const { cachedTasks, cacheTime } = JSON.parse(cachedData);
      const now = new Date().getTime();

      // Check if the cache is expired
      if (now - cacheTime < CACHE_EXPIRATION_TIME) {
        const { regularTasks, filteredDailyTasks } = separateTasks(cachedTasks);
        setTasks(regularTasks);
        setDailyTasks(filteredDailyTasks);
        updateDailyTaskProgress(filteredDailyTasks);
        
        refreshDynamicTasks(regularTasks, filteredDailyTasks);
        setLoading(false);
        return;
      } else {
        // Clear expired data
        sessionStorage.removeItem('cachedTasksData');
      }
    }
    loadAllTasks();
  }
}, [username]);

 
  // Function to refresh dynamic tasks with the latest data
  const refreshDynamicTasks = async (regularTasks, dailyTasks) => {
    const updatedTasks = await Promise.all(
      [...regularTasks, ...dailyTasks].map(async (task) => {
        if (task.id.startsWith('refer') || task.id.startsWith('mine')) {
          return await loadTaskData(task.id); // Reload progress for referral/mining tasks
        }
        return task; // Keep other tasks as-is
      })
    );
  
    const { regularTasks: updatedRegularTasks, filteredDailyTasks: updatedDailyTasks } = separateTasks(updatedTasks);
  
    setTasks(updatedRegularTasks);
    setDailyTasks(updatedDailyTasks);
    sessionStorage.setItem('cachedTasks', JSON.stringify(updatedTasks));
  };
  
  // Update loadAllTasks to store cache with timestamp
const loadAllTasks = async () => {
  const taskKeys = Object.keys(taskDetails);
  const allTaskPromises = taskKeys.map(async (taskId) => loadTaskData(taskId));
  const allTasks = await Promise.all(allTaskPromises);
  const { regularTasks, filteredDailyTasks } = separateTasks(allTasks);

  setTasks(regularTasks);
  setDailyTasks(filteredDailyTasks);
  updateDailyTaskProgress(filteredDailyTasks);

  // Store tasks with timestamp in session storage
  sessionStorage.setItem('cachedTasksData', JSON.stringify({ cachedTasks: allTasks, cacheTime: new Date().getTime() }));
  setLoading(false);
};

  const fetchTodayReferralCount = async () => {
    try {
      const response = await fetch(`https://softie.onrender.com/api/referrals/${username}`);
      const data = await response.json();
      return data.todaysReferralCount || 0;
    } catch (error) {
      console.error('Error fetching today’s referral count:', error);
      return 0;
    }
  };
  
  const updateDailyTaskProgress = (filteredDailyTasks) => {
    const completed = filteredDailyTasks.filter(task => task.claimed).length;
    setCompletedDailyTasks(completed);
    setTotalDailyTasks(filteredDailyTasks.length);
  
    // Check if all tasks are completed
    if (completed === filteredDailyTasks.length) {
      setKeyClaimed(false); // Reset if tasks are completed but key not yet claimed
    }
  };
  
  const getTodaysDate = () => {
    const today = new Date();
    return `${today.getFullYear()}${String(today.getMonth() + 1).padStart(2, '0')}${String(today.getDate()).padStart(2, '0')}`;
  };

  const isDailyTask = (taskId) => taskId.startsWith('daily');

// Modify loadTaskData to handle daily referral tasks
const loadTaskData = async (taskId) => {
  let taskKey = taskId;

  if (isDailyTask(taskId)) {
    const today = getTodaysDate();
    taskKey = `${taskId}-${today}`; // Append today's date to the daily task ID
  }

  const task = taskDetails[taskId];
  const [progressCount, claimed] = await Promise.all([
    fetchProgress(taskKey),
    fetchTaskStatus(taskKey),
  ]);

  const isClaimable = progressCount >= task.target && !claimed;
  let buttonText;

  if (claimed) {
    buttonText = "✔";
  } else if (isClaimable) {
    buttonText = "Claim";
  } else {
    buttonText = task.target === 1 ? task.action : `${progressCount}/${task.target}`;
  }

  return {
    id: taskKey, // Use the new task ID with the date
    ...task,
    progress: progressCount,
    claimed,
    buttonText,
  };
};

const fetchProgress = async (taskId) => {
  const today = getTodaysDate(); // Generate today's date in YYYYMMDD format
  const dailyReferralId = `daily-referrals-${today}`; // Append date to identifier

  if (taskId.startsWith('refer')) {
    return fetchReferralCount();
  } else if (taskId.startsWith('daily-referrals')) { // Use the updated identifier check for daily referrals
    return fetchTodayReferralCount();
  } else if (taskId.startsWith('mine')) {
    return fetchMiningSessionCount();
  } else {
    return 0;
  }
};

  const fetchTaskStatus = async (taskId) => {
    try {
      const response = await fetch(`https://softie.onrender.com/api/taskStatus/${username}/${taskId}`);
      const data = await response.json();
      return data.success && data.claimed;
    } catch (error) {
      console.error('Error fetching task status:', error);
      return false;
    }
  };

  const fetchReferralCount = async () => {
    try {
      const response = await fetch(`https://softie.onrender.com/api/referrals/${username}`);
      const data = await response.json();
      return data.referrals.length || 0;
    } catch (error) {
      console.error('Error fetching referral count:', error);
      return 0;
    }
  };

  const fetchMiningSessionCount = async () => {
    try {
      const response = await fetch(`https://softie.onrender.com/api/miningSessionCount/${username}`);
      const data = await response.json();
      return data.miningSessionCount || 0;
    } catch (error) {
      console.error('Error fetching mining session count:', error);
      return 0;
    }
  };

  const claimTask = async (taskId, reward) => {
    try {
      const response = await fetch(`https://softie.onrender.com/api/claimTask`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, taskId, reward })
      });

      const data = await response.json();

      if (response.ok && data.success) {
        showCustomAlert('Reward claimed successfully!');
        moveTaskToCompleted(taskId);
        return true;
      } else {
        showCustomAlert('Error claiming reward.');
        return false;
      }
    } catch (error) {
      console.error('Error claiming task:', error);
      showCustomAlert('Error claiming reward.');
      return false;
    }
  };

  const moveTaskToCompleted = (taskId) => {
    setTasks((prevTasks) => {
      const updatedTasks = prevTasks.map(task =>
        task.id === taskId ? { ...task, claimed: true, buttonText: '✔' } : task
      );
      sessionStorage.setItem('cachedTasks', JSON.stringify(updatedTasks));
      return updatedTasks;
    });
    setDailyTasks((prevDailyTasks) => {
      const updatedDailyTasks = prevDailyTasks.map(task =>
        task.id === taskId ? { ...task, claimed: true, buttonText: '✔' } : task
      );
      updateDailyTaskProgress(updatedDailyTasks);
      sessionStorage.setItem('cachedTasks', JSON.stringify(updatedDailyTasks));
      return updatedDailyTasks;
    });
  };
  
  const showCustomAlert = (message) => {
    setAlertMessage(message); // Set the custom alert message to trigger the alert
  };

  const handleButtonClick = (task) => {
  if (task.claimed || loadingTaskId === task.id) return; // Prevent multiple clicks or loading tasks from being clicked

  if (task.buttonText === "Claim") {
    setLoadingTaskId(task.id); // Set loading before claiming the task

    claimTask(task.id, task.reward).then((success) => {
      if (success) {
        moveTaskToCompleted(task.id);
      }
    }).catch((error) => {
      console.error("Error claiming task:", error);
      showCustomAlert('Error claiming reward.');
    }).finally(() => {
      setLoadingTaskId(null); // Reset loading state after task is claimed or fails
    });
  } else {
    const today = getTodaysDate();
    const taskUrlKey = task.id.startsWith('daily') ? task.id.replace(/-\d{8}$/, '') : task.id; // Remove appended date if daily
    const url = taskUrls[taskUrlKey]; // Use the taskUrls here

    if (url) {
      window.open(url, '_blank');
      setTasks(prevTasks =>
        prevTasks.map(t =>
          t.id === task.id ? { ...t, buttonText: "Claim" } : t
        )
      );
      setDailyTasks(prevDailyTasks =>
        prevDailyTasks.map(t =>
          t.id === task.id ? { ...t, buttonText: "Claim" } : t
        )
      );
    }
  }
};

const handleClaimKey = async () => {
  setLoadingKey(true); // Start loading when claim process begins
  try {
    const response = await fetch(`https://softie.onrender.com/api/incrementKey`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ username })
    });

    const data = await response.json();
    if (data.success) {
      setKeyClaimed(true); // Disable the button after claiming
      showCustomAlert('Good job! You have successfully claimed your key for today.');
    } else {
      showCustomAlert('Error claiming key.');
    }
  } catch (error) {
    console.error('Error claiming key:', error);
    showCustomAlert('Error claiming key.');
  } finally {
    setLoadingKey(false); // Stop loading after completion
  }
};

useEffect(() => {
  const fetchKeyClaimStatus = async () => {
    try {
      const response = await fetch(`https://softie.onrender.com/api/checkKeyClaimStatus`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username }),
      });
      
      const data = await response.json();
      if (data.success && data.keyClaimedToday) {
        setKeyClaimed(true);
      }
    } catch (error) {
      console.error("Error fetching key claim status:", error);
    }
  };

  if (username) {
    fetchKeyClaimStatus();
  }
}, [username]);


  const separateTasks = (tasks) => {
    const regularTasks = [];
    const filteredDailyTasks = [];

    tasks.forEach((task) => {
      if (task.id.startsWith('daily')) {
        filteredDailyTasks.push(task);
      } else {
        regularTasks.push(task);
      }
    });

    return { regularTasks, filteredDailyTasks };
  };

  return (
    <div className="tasks-container app">
      <h2 style={{ marginTop: '25px', fontSize: '30px' }}>Tasks</h2>
      <div className="daily-task-progress">
  <div className="order">
    <div style={{ width: '90%', padding: '0 30px' }}>
      <p style={{ width: '100%', background: 'none', border: 'none', color: 'white', fontSize: '8px', marginLeft: '-6px' }}>
        Complete all the Daily Tasks and claim your Key
      </p>
      <div className="progress-bar-container">
        {!loading && completedDailyTasks === totalDailyTasks ? (
          <button
            className="key-button"
            style={{ backgroundColor: keyClaimed ? "gray" : "#0bda13", color: "white" }}
            onClick={handleClaimKey}
            disabled={keyClaimed || loadingKey} // Disable if key is claimed or loading
          >
            {loadingKey ? "Processing..." : keyClaimed ? "✔" : "Claim Key"}
          </button>
        ) : (
          <div className="progress-bar" style={{ width: `${(completedDailyTasks / totalDailyTasks) * 100}%` }} />
        )}
      </div>
      {/* Conditionally render the task progress paragraph */}
      {completedDailyTasks < totalDailyTasks && (
        <p style={{ background: 'none', border: 'none', marginTop: '-30px', fontSize: '15px', color: '#041f4c', fontWeight: 'bold' }}>
          {completedDailyTasks}/{totalDailyTasks}
        </p>
      )}
    </div>
    <div className="key-image">
      <img src="/key.png" alt="key" />
    </div>
  </div>
</div>
      <div className="tasks-header">
        <h2
          className={`tasks-title ${showDailyTasks ? 'active' : ''}`}
          onClick={() => setShowDailyTasks(true)}
        >
          Daily Tasks
        </h2>
        <h2
          className={`tasks-title ${!showDailyTasks ? 'active' : ''}`}
          onClick={() => setShowDailyTasks(false)}
        >
          General Tasks
        </h2>
      </div>

      {showDailyTasks ? (
        
        <div id="tasks-container">
          {loading ? (
            <div style={{ marginLeft: '-20px'}}>
              <SoundWaveLoader />
            </div>
          ) : (
            dailyTasks.map((task) => (
              <TaskItem
                key={task.id}
                task={task}
                handleButtonClick={handleButtonClick}
                loadingTaskId={loadingTaskId}
              />
            ))
          )}
        </div>

      ) : (
        <div id="tasks-container">
          {loading ? (
            <div style={{ marginLeft: '-20px'}}>
              <SoundWaveLoader />
            </div>
          ) : (
            tasks.map((task) => (
              <TaskItem
                key={task.id}
                task={task}
                handleButtonClick={handleButtonClick}
                loadingTaskId={loadingTaskId}
              />
            ))
          )}
        </div>
      )}

      <BottomNav activeItem={activeItem} setActiveItem={setActiveItem} />
      {alertMessage && <CustomAlert message={alertMessage} onClose={closeAlert} />}

    </div>
  );
}
