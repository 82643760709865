import React, { useEffect, useState } from 'react';
import './Deposit.css';
import CustomAlert from './CustomAlert';
import { useNavigate, Routes, Route } from 'react-router-dom';

export default function Deposit() {
  const [walletAddress, setWalletAddress] = useState('0xE03F7773FC37E674B65A812667dC945382cE9A6b'); // Example wallet address
  const [alertMessage, setAlertMessage] = useState(null); // Alert message state
  const [bnbRate, setBnbRate] = useState(0); // Store the BNB to USD rate
  const [usdAmount, setUsdAmount] = useState(''); // USD amount input
  const [bnbEquivalent, setBnbEquivalent] = useState('0.00'); // Calculated BNB equivalent
  const [isExpanded, setIsExpanded] = useState(false); // Control the instruction list expansion
  const [isChecked, setIsChecked] = useState(false); // Control checkbox state
  const [transactionId, setTransactionId] = useState(''); // Transaction ID input
  const [username, setUsername] = useState(''); // Username from local storage
  const navigate = useNavigate();
  const [timeRemaining, setTimeRemaining] = useState("");

  useEffect(() => {
    const targetDate = new Date("2024-12-20T00:00:00Z").getTime();

    const updateTimer = () => {
      const now = new Date().getTime();
      const distance = targetDate - now;

      if (distance <= 0) {
        setTimeRemaining("Countdown completed!");
        clearInterval(timerInterval);
        return;
      }

      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      setTimeRemaining(`${days}d ${hours}h ${minutes}m ${seconds}s`);
    };

    const timerInterval = setInterval(updateTimer, 1000);

    return () => clearInterval(timerInterval);
  }, []);


  const closeAlert = () => {
    setAlertMessage(null); // Reset alertMessage to hide the alert
  };

const goBack = () => {
    navigate('/softie'); // Navigate back to the /softie page
  };
  
  const historyPage = () => {
    navigate('/history'); // Navigate back to the /softie page
  };
  
  // Fetch username from local storage
  useEffect(() => {
    const storedUsername = localStorage.getItem('username'); // Replace 'username' with the correct key
    if (storedUsername) {
      setUsername(storedUsername);
    } else {
      setAlertMessage('No username found in local storage.');
    }
  }, []);

  // Fetch BNB to USD exchange rate from CoinGecko
  useEffect(() => {
    fetch('https://api.coingecko.com/api/v3/simple/price?ids=binancecoin&vs_currencies=usd')
      .then(response => response.json())
      .then(data => {
        setBnbRate(data.binancecoin.usd); // Set the BNB to USD rate
      })
      .catch(error => {
        console.error('Error fetching BNB rate:', error);
        setAlertMessage('Error fetching BNB to USD exchange rate.');
      });
  }, []);

  // Handle copying the wallet address to clipboard
  const copyToClipboard = () => {
    navigator.clipboard.writeText(walletAddress)
      .then(() => {
        setAlertMessage(`Wallet address copied to clipboard!`);
      })
      .catch(err => {
        console.error('Failed to copy text:', err);
        setAlertMessage('Failed to copy wallet address.');
      });
  };

  // Dynamically update BNB equivalent based on USD amount input
  const handleUsdInputChange = (e) => {
    const amount = e.target.value;
    setUsdAmount(amount);
    if (bnbRate > 0 && amount) {
      const bnb = (amount / bnbRate).toFixed(4); // Convert USD to BNB and format to 4 decimals
      setBnbEquivalent(bnb);
    } else {
      setBnbEquivalent('0.00');
    }
  };

// Handle form submission
const handleSubmit = async () => {
  const amount = parseFloat(usdAmount);
  if (!amount || isNaN(amount) || !transactionId) {
    setAlertMessage('Amount and Transaction ID fields are required.');
    return;
  }

  if (amount < 5) {
    setAlertMessage('The minimum deposit amount is $5.');
    return;
  }

  // Show the "Processing..." alert message
  setAlertMessage('Processing...');

  try {
    const response = await fetch('https://softie.onrender.com/api/payments', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        username, // Use the username from local storage
        amount,
        transactionId,
        cryptoType: 'BNB'
      })
    });

    const data = await response.json();
    if (response.ok) {
      setAlertMessage('Payment submitted successfully. You will receive an email confirmation shortly.');
      setTimeout(() => {
        window.location.href = '/softie';
      }, 4000);
    } else {
      setAlertMessage(`Error: ${data.message}`);
    }
  } catch (error) {
    console.error('Error submitting deposit:', error);
    setAlertMessage('An error occurred while submitting your payment. Please try again later.');
  }
};

  return (
    <div className="deposit app">
      <a onClick={goBack}>
        <i className="fas fa-arrow-left" style={{ color: 'white', position: 'fixed', left: '18px', top: '40px', zIndex: '500', fontSize: '20px' }}></i>
      </a>
    <div>
      {/* Instructions Section */}
      <div className={`instructions ${isExpanded ? 'expanded' : ''}`}>
        <h1 style={{ marginTop: '17px', fontSize: '30px'}}>Deposit</h1>
        <img src="/new/testnetad.png" alt="Testnet" style={{ width: '100%', margin: '10px auto' }} />
        <div style={{ margin: '0 auto', background: '#d7edff1a', padding: '20px', marginTop: '30px', width: '90%', paddingLeft: '10px'}}>
        <p style={{ color: '#48e4fa', fontSize: '10px', width: '100%' }} id="prehamble">Read these instructions carefully before submission</p>
        <ol>
          <li>Deposit can only be made with BNB(BEP20), sending any other asset to the provided wallet address will result in a permanent loss.</li>
          <li>Enter the amount you want to deposit in USD, and the equivalent amount in BNB will be displayed.</li>
          <li>Copy the wallet address provided, and send the exact amount displayed to the wallet address you copied.</li>
          <li>After the deposit has been transferred successfully, copy the transaction ID, and come back to this page.</li>
          <li>Check that you have made the deposit and copied the transaction ID, paste the transaction Id in the box provided, and then submit.</li>
          <li>After submission, your transaction will be confirmed by the team and your commitment account will be credited. This might take between 2 to 24 hours, please be patient.</li>
          <li>Your earning begins immediately your deposit is confirmed.</li>
        </ol>
        <button className="toggle-btn" onClick={() => setIsExpanded(!isExpanded)}>
          {isExpanded ? '▲' : '▼'}
        </button>
        </div>
      </div>
      </div>
      <div>

      {/* Amount Input Section */}
      <div className="amount">
        <div className="amount-input">
          <input
            type="number"
            id="usd-amount"
            placeholder="Enter amount in USD"
            value={usdAmount}
            onChange={handleUsdInputChange}
          />
        </div>

        <div className="conversion-result">
          {bnbEquivalent} <span>BNB</span>
        </div>
      </div>

      {/* Wallet Address Section */}
      <div className="wallet-address">
        <input
          type="text"
          id="wallet"
          value={walletAddress}
          readOnly
        />
        <button id="copy-wallet-btn" onClick={copyToClipboard}>Copy Wallet Address</button>
      </div>
      
      </div>
      <div>

      {/* Checkbox and Transaction ID Section */}
      <div className="transaction">
        <label style={{ display: 'flex', alignItems: 'center', fontSize: '10px', marginLeft: '-100px'}}>
          <input
            type="checkbox"
            checked={isChecked}
            onChange={() => setIsChecked(!isChecked)}
            style={{ marginRight: '-110px',  height: '20px' }}
          />
          I have made the deposit and copied the transaction ID.
        </label>

        {isChecked && (
          <>
            <input
              type="text"
              id="transaction-id"
              placeholder="Enter Transaction ID"
              value={transactionId}
              onChange={(e) => setTransactionId(e.target.value)}
              style={{ marginTop: '10px' }}
            />
            <button id="submit-btn" onClick={handleSubmit} style={{ marginTop: '10px' }}>Submit</button>
          </>
        )}
      </div>
      </div>
      <a style={{ marginTop: '60px', textDecoration: 'none', width: '60%', margin: '60px auto'}} className="history" onClick={historyPage}>View Transaction History</a>

      {/* Custom Alert */}
      {alertMessage && <CustomAlert message={alertMessage} onClose={closeAlert} />}
    </div>
  );
}
