import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './ConnectWallet.css'; 
import CustomAlert from './CustomAlert';

export default function ConnectWallet() {
  const [walletAddress, setWalletAddress] = useState('');
  const [loading, setLoading] = useState(false); 
  const [alertMessage, setAlertMessage] = useState(null); 
  const [isUpdating, setIsUpdating] = useState(false);  // Track if user is updating the wallet address
  const navigate = useNavigate();

  const closeAlert = () => {
    setAlertMessage(null);
  };

  const isValidWalletAddress = (address) => {
    const regex = /^0x[a-fA-F0-9]{40}$/;
    return regex.test(address);
  };
  
  const goBack = () => {
    navigate('/home'); // Navigate back to the /softie page
  };

  useEffect(() => {
    const fetchUserData = async () => {
      const username = localStorage.getItem('username');
      if (!username) {
        console.error('Username not found in local storage');
        return;
      }
      try {
        const response = await fetch(`https://softie.onrender.com/api/profile/${username}`);
        const data = await response.json();
        if (data.walletAddress) {
          setIsUpdating(true);  // If walletAddress exists, the user is updating it
          setWalletAddress(data.walletAddress);  // Pre-fill the wallet address
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();
  }, []);

  const handleConnectWallet = async (e) => {
    e.preventDefault();

    if (!isValidWalletAddress(walletAddress)) {
      showCustomAlert('Invalid wallet address. Please enter a valid BEP-20 address.');
      return;
    }

    setLoading(true);

    const username = localStorage.getItem('username');

    try {
      const response = await fetch(`https://softie.onrender.com/api/connect-wallet`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, walletAddress }),
      });

      const data = await response.json();
      if (data.success) {
        showCustomAlert(isUpdating ? 'Wallet address updated successfully!' : 'Wallet address connected successfully!');
        setTimeout(() => {
          navigate('/home');
        }, 2000);  
      } else {
        showCustomAlert(isUpdating ? 'Failed to update wallet address.' : 'Failed to connect wallet address.');
      }
    } catch (error) {
      console.error('Error connecting wallet:', error);
      showCustomAlert('An error occurred. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const showCustomAlert = (message) => {
    setAlertMessage(message);
  };

  return (
    <div className="connect-page app">
      <a onClick={goBack}>
        <i className="fas fa-arrow-left" style={{ color: 'white', position: 'fixed', left: '20px', top: '40px', zIndex: '500', fontSize: '20px' }}></i>
      </a>
      <img src="softies/deposit.png" alt="Connect Wallet"/>
      <h3>{isUpdating ? 'UPDATE YOUR BEP-20 WALLET' : 'CONNECT YOUR BEP-20 WALLET'}</h3>
      <p className="para">
        {isUpdating 
          ? 'You are about to update your existing wallet address. Ensure the new BEP-20 address is correct for easy withdrawal of your earnings and to receive your airdrop.'
          : 'You need to connect your crypto wallet (BEP-20) for easy withdrawal of your earnings, and to receive your airdrop.'
        }
      </p>
      <input
        type="text"
        id="wallet-address"
        placeholder="Paste Your Wallet Address Here"
        value={walletAddress}
        onChange={(e) => setWalletAddress(e.target.value)}
        required
      />
      <button
        style={{ width: '50%' }}
        className="submit-button"
        type="submit"
        onClick={handleConnectWallet}
        disabled={loading}
      >
        {loading ? (isUpdating ? 'Updating...' : 'Connecting...') : (isUpdating ? 'Update' : 'Connect')}
      </button>
      {alertMessage && <CustomAlert message={alertMessage} onClose={closeAlert} />}
    </div>
  );
}
